.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  font-size: calc(10px + 2vmin);
  text-decoration: none;
}

.btn-link:hover {
  color: #f9f7f4;
  background-color: #04c3ee;
  border-color: #5b288e;
}

.modal-content {
  background: #1a0933;
}